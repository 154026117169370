import { useState } from 'react';

const Job = ({ jobTitle, jobDuration, skills }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleSkills = () => {
        setIsExpanded(!isExpanded);
    };

    const displayedSkills = isExpanded ? skills : skills.slice(0, 2);
    const showToggleButton = skills.length > 2;

    return (
        <div className="job-container">
            <h4 className="job-title">{jobTitle}</h4>
            <p className="job-duration">{jobDuration}</p>

            <ul className="skills-list custom-bullets">
                {displayedSkills.map((skill, index) => (
                    <li key={index}>{skill}</li>
                ))}
            </ul>

            {showToggleButton && (
                <button onClick={toggleSkills} className="toggle-skills-button">
                    {isExpanded ? 'Less' : 'More'}
                </button>
            )}
        </div>
    );
};

const ExperienceContent = () => {
    return (
        <div id="experience-section">
            <h4 className="section-title">Experience</h4>

            <div className="company-container" id="higher-ground">
                <h3 className="company-name">Higher Ground LLC</h3>
                <p className="location">Palo Alto, California, United States</p>

                <Job
                    jobTitle="Senior Software Engineer"
                    jobDuration="Jul 2024 - Present"
                    skills={[
                        'Software Infrastructure',
                        'Full-Stack Development',
                        'Backend Web Development',
                        'Docker',
                        'Jenkins',
                        'Django REST Framework',
                        'Kotlin',
                        'NestJS',
                        'REST APIs',
                        'Azure Virtual Machines',
                        'Azure SQL',
                        'Redis',
                        'Vite',
                        'React',
                    ]}
                />

                <Job
                    jobTitle="Member Of Technical Staff"
                    jobDuration="Jun 2021 - Jul 2024"
                    skills={[
                        'Application Programming Interfaces (API)',
                        'Embedded Software Programming',
                        'Android Development',
                        'REST APIs',
                    ]}
                />
            </div>

            <div className="company-container" id="attochron">
                <h3 className="company-name">Attochron LLC</h3>
                <p className="location">Lexington, Virginia</p>

                <Job
                    jobTitle="Electrical and Instrumentation Engineer"
                    jobDuration="Jan 2020 - Jul 2021"
                    skills={[
                        'Application Programming Interfaces (API)',
                        'Embedded Software Programming',
                        'Hardware-Software Integration',
                        'Data Processing and Visualization',
                        'Web Development',
                    ]}
                />
            </div>

            <div className="company-container" id="washington-lee">
                <h3 className="company-name">Washington and Lee University</h3>
                <p className="location">Lexington, Virginia</p>

                <Job
                    jobTitle="Data Science Research Associate"
                    jobDuration="Sep 2020 - Jun 2021"
                    skills={[
                        'Machine learning',
                        'Deep learning for image processing',
                        'Python',
                        'AWS',
                        'Scikit-Learn',
                        'Pandas',
                        'NumPy',
                        'Image Manipulation',
                        'Jupyter Notebook',
                    ]}
                />

                <Job
                    jobTitle="Teaching Assistant - Electrical Circuits Lab"
                    jobDuration="Sep 2019 - Jan 2020"
                    skills={[
                        'Circuit design, construction, and testing',
                        'MCU Programming in C',
                        'MatLab',
                    ]}
                />
            </div>
        </div>
    );
};

export default ExperienceContent;