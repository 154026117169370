import React from "react";

import "./InfrastructurePage.css";

const InfrastructureProjects = () => {

    return (
        <div>
            <h1>Infrastructure Projects</h1>
            <p>Coming soon...</p>
        </div>
    )
}

export default InfrastructureProjects;
