import React, { useEffect, useState, useRef } from 'react';
import './ImageCarousel.css';

/**
 * A basic image carousel component that will make the first image glow when the component is rendered
 * and remove the glow when the user hovers over the first image.
 * @param {array} imageResources - An array of image resources in the form of strings.
 * @returns {JSX} A JSX element representing an image carousel.
 */
const ImageCarousel = ({ imageResources }) => {
  const firstImageRef = useRef(null);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (firstImageRef.current && !hovered) {
      
      firstImageRef.current.classList.add('glow');
    }
  }, [hovered]);

  const handleMouseEnter = () => {
    if (firstImageRef.current && !hovered) {
      firstImageRef.current.classList.remove('glow');
      setHovered(true);
    }
  }

  return (
    <div className="basic-carousel">
      {imageResources.map((src, index) => (
        <div 
        key={index} 
        className="basic-imageContainer" 
        ref={index === 0 ? firstImageRef : null}
        onMouseEnter={index ===0? handleMouseEnter : null}
      >
        <img src={src} alt={`Image ${index}`} className="basic-image" />
      </div>
      ))}
    </div>
  );
};

export default ImageCarousel;