import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Card, CardBody, CardHeader } from "@nextui-org/react";

const TDLAPIProject = () => {
    return (
        <div className='project-wrapper'>
            <div className='my-4'>
                <h4>Tactical Data Link (TDL) API</h4>
                <ul className="list-disc pl-8">
                    <li className="text-2xl">Linked HTTP Stream from Anduril's Lattice common operating picture (COP) to our satellite messaging system.</li>
                </ul>
            </div>
        </div>
    );
}

const TRAXMiddleWareProject = () => {
    return (
        <div className='project-wrapper'>
            <div className='my-4'>
                <h4>SpaceLinq TRAX Middleware</h4>
                <ul className="list-disc pl-8">
                    <li className="text-2xl">Authenticated and forwarded messages from Sierra Nevada Corporations TRAX application to our satellite messaging system.</li>
                </ul>
            </div>
        </div>
    );
}

const APIProjectsList = Object.freeze([
    { id: 'tdl', label: 'TDL API', content: <TDLAPIProject /> },
    { id: 'trax', label: 'TRAX Middleware', content: <TRAXMiddleWareProject /> },
]);

const APIProjects = () => {
    return (
        <section className="project-grouping">
            <Tabs items={APIProjectsList} color={'warning'}>
                {(item) => (
                    <Tab key={item.id} title={item.label} className="project-tabs">
                        {item.content}
                    </Tab>
                )}
            </Tabs>
        </section>
    );
};

export default APIProjects;