import React, { useEffect, useState } from 'react';
import "./PortfolioPage.css";

import resume from '../assets/resume/Jeffrey Bradley - Resume.pdf';
import avatar from '../assets/images/my-avatar.png';
import githubLogo from '../assets/images/icon-logo-github.png';
import linkedinLogo from '../assets/images/icon-logo-linkedin.png';
import mail from '../assets/images/icon-email-50.png';
import { Document, Page, pdfjs } from 'react-pdf';
import { Tabs, Tab, Card, CardBody, CardHeader } from "@nextui-org/react";
import "pdfjs-dist/build/pdf.worker.mjs";

import  InfrastructureProjects from "./Infrastructure/InfrastructurePage";
import AndroidProjects from './Android/AndroidProjects';
import ExperienceContent from './Experience/Experience';
import WebProjects from './Web/WebProjects';
import APIProjects from './APIs/APIProjects';

const PortFolioTopImage = () => {
    return (
        <div>
            <img id="my-avatar" src={avatar} alt="Jeff as a Cartoon" />
        </div>
    );
};


const Contacts = ({ img, type, contactInfo }) => {
    return (
        <section className="contact-info">
            <a href={type === "Email" ? `mailto:${contactInfo}` : null}>
                <img src={img} alt={type} />
            </a>
            <div className="contact-info-text">
                <h4>{type}</h4>
                <p>{contactInfo}</p>
            </div>
        </section>
    )
}

const PortfolioHeader = () => {
    return (
        <section id="portfolio-page-header">
            <PortFolioTopImage />
            <h3>
                Jeffrey Bradley
            </h3>
            <ul>
                <li>
                    <a href="https://github.com/JeffreyBradley772">
                        <img src={githubLogo} alt="My Github Profile" />
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/jeffreybradley772/">
                        <img src={linkedinLogo} alt="My LinkedIn Profile" />
                    </a>
                </li>
                <hr />
            </ul>

            <Contacts img={mail} type="Email" contactInfo="JeffreyBradley772@gmail.com" />
        </section>
    );
};

const ProjectsContainer = () => {

    const [selectedOption, setSelectedOption] = useState('Resume');

    return (
        <section id="projects-wrapper">
            <CornerOptions selectedOption={selectedOption} setSelectedOption={setSelectedOption} />

            <div className="content">
                {selectedOption === 'Experience' && <ExperienceContent />}
                {selectedOption === 'Portfolio' && <PortfolioContent />}
                {selectedOption === 'About Me' && <AboutContent />}
                {selectedOption === 'Resume' && <ResumeContent />}
            </div>
        </section>
    )
};

const CornerOptions = ({ selectedOption, setSelectedOption }) => {
    return (
        <section id="corner-options">
            <ul>
                <li className={selectedOption === 'About Me' ? 'active' : ''} onClick={() => { setSelectedOption('About Me') }}>
                    <button>About me</button>
                </li>
                <li className={selectedOption === 'Experience' ? 'active' : ''} onClick={() => { setSelectedOption('Experience') }}>
                    <button>Experience</button>
                </li>
                <li className={selectedOption === 'Resume' ? 'active' : ''} onClick={() => { setSelectedOption('Resume') }}>
                    <button>Resume</button>
                </li>
                <li className={selectedOption === 'Portfolio' ? 'active' : ''} onClick={() => { setSelectedOption('Portfolio') }}>
                    <button>Portfolio</button>
                </li>
                <li className={selectedOption === 'Home' ? 'active' : ''} onClick={() => { window.location.href = "/"; }}>
                    <button>Home</button>
                </li>
            </ul>
        </section>
    );
}

const PortfolioContent = () => {
    const projects = ["Android", "Web", "APIs/Middleware", "Infrastructure"]

    const [selectedProject, setSelectedProject] = useState(projects[0])

    return (
        <div>
            <h4>Portfolio</h4>
            <ProjectOptions projects={projects} selectedProject={selectedProject} setSelectedProject={setSelectedProject} />
            {selectedProject === "Android" && < AndroidProjects />}
            {selectedProject === "Web" && < WebProjects />}
            {selectedProject === "APIs/Middleware" && < APIProjects />}
            {selectedProject === "Infrastructure" && < InfrastructureProjects />}
        </div>
    );
}

const AboutContent = () => {
    return (
        <div>
            <h4>About me</h4>
            <br />
            <p style={{ fontSize: '1.5rem', textIndent: '25px', lineHeight: '2rem', fontWeight: 'bold' }}>
                I've had the privilege of working at startups for most of my career, which has taught me to be comfortable wearing many hats. I thrive in environments where no two days are the same and I'm constantly learning new technologies. I'm always on the lookout for the next tool or technique that will help me work more efficiently and effectively.
            </p>
            <br />
            <p style={{ fontSize: '1.5rem', textIndent: '25px', lineHeight: '2rem', fontWeight: 'bold' }}>
                My passion is building systems that optimize the experience for engineers, developers, and customers alike. Whether it's streamlining a development workflow, building a scalable infrastructure, or creating a user-friendly interface, I love finding ways to make complex systems simple and easy to use.
            </p>
        </div>
    );
}

const PdfViewer = ({ pdfUrl }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1); // Reset to first page
    };

    const changePage = (offset) => {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    };

    return (
        <div>
            <Document
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={pageNumber} />
            </Document>
            <div>
            </div>
        </div>
    );
};

const ResumeContent = () => {

    return (
        <div>
            <a href="Jeffrey Bradley - Resume.pdf" download>
                <button className='btn' style={{ paddingBottom: '10px', marginBottom: '10px' }}>
                    Download PDF
                </button>
            </a>
            <PdfViewer pdfUrl={resume} />
        </div>

    );
}

const ProjectOptions = ({ projects, selectedProject, setSelectedProject }) => {
    return (
        <ul>
            {projects.map((project, index) => (
                <li className='project-option' key={index}>
                    <nav
                        onClick={() => setSelectedProject(project)}
                        className={`${selectedProject === project ? 'selected' : ''}`}
                    >
                        {project}
                    </nav>
                </li>
            ))}
        </ul >
    )
}

function PortfolioPage() {
    return (
        <React.Fragment>
            <h4 style={{ textAlign: 'center' }}>**Work in progress...**</h4>
            <div className="portfolio-wrapper">
                <PortfolioHeader />
                <ProjectsContainer />
            </div>
        </React.Fragment>
    );
}

export default PortfolioPage;