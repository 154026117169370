import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Card, CardBody, CardHeader } from "@nextui-org/react";
import ImageCarousel from '../../components/imageCarousel/ImageCarousel';
import ImageStack from '../../components/imageStack/ImageStack';



import e2eProject1 from '../../assets/images/projects/e2e-project1.png';
import e2eProject2 from '../../assets/images/projects/e2e-project2.png';
import e2eProject3 from '../../assets/images/projects/e2e-project3.png';

import imageprocessing1 from '../../assets/images/projects/image-workflow/imageprocessing1.png';
import imageprocessing2 from '../../assets/images/projects/image-workflow/imageprocessing2.png';
import imageprocessing3 from '../../assets/images/projects/image-workflow/imageprocessing3_compression.png';
import imageprocessing4 from '../../assets/images/projects/image-workflow/imageprocessing4_convo.png';

import atak1 from '../../assets/images/projects/atak/atak-message-list.png';
import atak2 from '../../assets/images/projects/atak/atak-conversation-page.png';

import pointGif from '../../assets/images/projects/pointing/pointing_gif.gif';
import './AndroidProjects.css';


const E2eProject = () => {
    const elementsList = ["UI/UX", "System"]
    const [selectedElement, setSelectedElement] = useState(elementsList[0]);

    const handleElementChange = (element) => {
        setSelectedElement(element);
    };

    return (
        <div className='project-wrapper'>
            <div className='my-4'>
                <h4>End-to-End Encryption in Satellite Messaging</h4>
                <ul className="list-disc pl-8">
                    <li className="text-2xl">Technical lead in full stack development of end-to-end encryption over satellite messaging.</li>
                    <li className="text-2xl">Leveraged the FIPS 140-3 Bouncy Castle library to incorporate hybrid encryption and authentication.</li>
                    <li className="text-2xl">Implemented a secure key exchange protocol to ensure secure communication between servers and end device.</li>
                    <li className="text-2xl">Lead small team of engineers who assisted in android and backend development.</li>
                    <li className="text-2xl">Worked directly with the final customer on design choices to ensure the best final user experience.</li>
                </ul>
            </div>

            <div className="flex-container">
                {elementsList.map((element, index) => (
                    <button
                        key={index}
                        className={`button ${selectedElement === element ? 'active' : ''}`}
                        onClick={() => handleElementChange(element)}
                    >
                        {element}
                    </button>
                ))}
            </div>

            {selectedElement === "UI/UX" && (
                <>
                    <p className="text-2xl">The UI/UX below is simple because its SUPPOSED to be! The complex and clever parts happen all in the background. Having control of every technology in the design from the public keys database, the APIs between our servers and the satellite base station, and the user interface, was crucial in my ability to make this a fantastic and secure user experience. We recently demoed and and delivered the final product to a happy customer and are moving forward with a rebranding of the implementation.</p>
                    < ImageCarousel imageResources={[e2eProject1, e2eProject2]} />
                    {/* <div className='grid grid-cols-[1fr_1fr] items-center justify-center gap-5 py-20'>
                        <img className='h-[50rem] justify-self-center' src={e2eProject1} />
                        <img className='h-[50rem] justify-self-center' src={e2eProject2} />
                    </div> */}
                </>
            )}

            {selectedElement === "System" && (
                <>
                    <p className="text-2xl">
                        Here is a basic overview of the system design. For user-to-user messaging, messages are transferred via hybrid key exchange. Every message is verified to be using the correct public key.

                        For group messaging, the group owner generates and shares a symmetric via the original encrypted message design that each group member uses to encrypt and decrypt messages.
                    </p>

                    <div className='grid grid-cols-[1fr] gap-4 items-center justify-center gap-20 py-20'>
                        <img className='h-[50rem] justify-self-center' src={e2eProject3} />
                    </div>
                </>
            )}

        </div>
    );
}


const AtakPluginProject = () => {

    const imageList = [atak1, atak2];
    return (
        <div className='project-wrapper'>
            <div className='my-4'>
                <h4>SpaceLinq ATAK Plugin</h4>
                <ul className="list-disc pl-8">
                    <li className="text-2xl">Miniaturized the entire satellite messaging application into a lightweight plugin that functioned within the architecture of the full military and consumer version of the Android Tactical Awareness Kit (ATAK) app.</li>
                </ul>
                <div className='project-photos'>
                    <ImageStack imageResources={imageList} />
                </div>
            </div>
        </div>
    );
}

const ImagesProject = () => {
    const imageList = [imageprocessing1, imageprocessing2, imageprocessing3, imageprocessing4];

    return (
        <div className='project-wrapper'>
            <div className='my-4'>
                <h4>Images Processing Workflow</h4>
                <ul className="list-disc pl-8">
                    <li className="text-2xl">Developed a photo message compression workflow for the Higher Ground messaging app. This workflow allows users to take a photo, crop it, and compress it to a size that can be transmitted over the satellite network. While minimizing latency in the app UX and time over the satellite, it was important to be transparent of how long a message is expected to send over the satellite based on how much a user compresses it.</li>
                </ul>
                <div className='project-photos'>
                    <ImageCarousel imageResources={imageList} />
                </div>
            </div>
        </div>
    );
}

const PointingProject = () => {
    const imageList = [pointGif]

    return (
        <div className='project-wrapper'>
            <div className='my-4'>
                <h4>Satellite Pointing Guidance</h4>
                <ul className="list-disc pl-8">
                    <li className="text-2xl">Developed azimuth, elevation, and skew pointing guidance for the Higher Ground messaging app.</li>
                </ul>
                <div className='project-photos'>
                    <ImageCarousel imageResources={imageList} />
                </div>
            </div>
        </div>
    );

}

const AndroidProjectsList = Object.freeze([
    { id: 'e2e', label: 'E2E Encryption in Satellite Messaging', content: <E2eProject /> },
    { id: 'images', label: 'Images Processing Workflow', content: <ImagesProject /> },
    { id: 'atak', label: 'ATAK PLUGIN', content: <AtakPluginProject /> },
    { id: 'pointing', label: 'Satellite Pointing Guidance', content: <PointingProject /> },
]);

const AndroidProjects = () => {
    return (
        <section className="project-grouping">
            <Tabs items={AndroidProjectsList} color={'warning'}>
                {(item) => (
                    <Tab key={item.id} title={item.label} className="project-tabs">
                        {item.content}
                    </Tab>
                )}
            </Tabs>
        </section>
    );
};

export default AndroidProjects;

/**
 * 
 *                 <div className="image-workflow-outer">
                    <div className='button-space'>
                        {imageIndex > 0 &&
                            <button className='arrow-button' onClick={() => setImageIndex(imageIndex - 1)}>
                                <span class="arrow">←</span>
                            </button>
                        }
                    </div>
                    <div className="image-workflow-wrapper">
                        <img id="prev-image" src={imageList[imageIndex - 1]}></img>
                        <img id="current-image" src={imageList[imageIndex]}></img>
                        <img id="next-image" src={imageList[imageIndex + 1]}></img>
                    </div>
                    <div className='button-space'>
                        {imageIndex < imageList.length - 1 &&
                            <button className='arrow-button' onClick={() => setImageIndex(imageIndex + 1)}>
                                <span class="arrow">→</span>
                            </button>
                        }
                    </div>
                </div>
 */