import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Card, CardBody, CardHeader } from "@nextui-org/react";

const SpaceLinqForWebProject = () => {
    return (
        <div className='project-wrapper'>
            <div className='my-4'>
                <h4>SpaceLinq for Web</h4>
                <ul className="list-disc pl-8">
                    <li className="text-2xl">Web app to allow online users to communicate with satellite only users on any web enabled device.</li>
                </ul>
            </div>
        </div>
    );
}

const MCSxProject = () => {
    return (
        <div className='project-wrapper'>
            <div className='my-4'>
                <h4>Django Admin Console for Database Management</h4>
                <ul className="list-disc pl-8">
                    <li className="text-2xl">Modernized database management for satellite channel and customer information by designing a Django-based admin page with an updated schema that reduced database size by over 80%.</li>
                    <li className="text-2xl">Deployed the admin web page on an Azure VM instance using Docker Compose, significantly enhancing data security and efficiency by reducing database exporting from an hours long manual job to an automated instant task.</li>
                    <li className="text-2xl">Collaborated with Sales and customers to add custom features to display output the the data to meet each unique need.</li>
                </ul>
            </div>
        </div>
    );
}

const WebProjectsList = Object.freeze([
    { id: 'Spacelinq for Web', label: 'Web to Satellite Messaging App', content: <SpaceLinqForWebProject /> },
    { id: 'MCSx', label: 'Django Admin Console', content: <MCSxProject /> }
]);

const WebProjects = () => {
    return (
        <section className="project-grouping">
            <Tabs items={WebProjectsList} color={'warning'}>
                {(item) => (
                    <Tab key={item.id} title={item.label} className="project-tabs">
                        {item.content}
                    </Tab>
                )}
            </Tabs>
        </section>
    );
};

export default WebProjects;